import React, { forwardRef } from 'react'

import style from './style.module.scss'

export const BackDrop = forwardRef((props, ref) => {
  return (
    <div className={`${style.backdrop} ${props.isActive && style.active}`}>
      <div className={style.content}>{props.children}</div>
    </div>
  )
})
