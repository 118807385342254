import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'email-validator';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import floatingElement from '../../assets/images/circuit.png';
import { SERVICES } from './services.data';
import { Modal } from '../../Components';

import { scrollToTop } from '../../utils/scrollToTop';
import { ScrollTop } from '../../Components/ScrollTop';
import axios from "axios";
import emailjs from "@emailjs/browser";
export const ServiceModal = ({ showModal, setShowModal, setShowToast }) => {

  const [input, setInput] = useState({
    name: '',
    email: '',
    phone: ''
  })

  const [enquireType,setEnquireType]=('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [Organization,setOrganization]=useState('');
  const checkData = () => {
    if (!name || !validate(email)|| phone.length!==10) {
      return false;
      
    }
    return true;
  };

console.log(name,email,phone)
  const handleClick=async(event)=>{
    event.preventDefault();
    var data={
      message_type:"Service Enquiry",
      from_name:name,
      from_email:email,
      course_name:"Basic Computing",
      from_phone:phone,
      from_org:Organization,
      message:"This message is from service Enquriy Page"
    }
    if(!checkData()){
      if(phone.length!==10){
        alert("please enter a 10 digit Number");
      }
      else if (!name){
        alert("please enter name");
      }
      else if(!validate(email)){
        alert("Please enter a valid email");
      }
    }
    else{

    console.log(process.env.REACT_APP_SERVICE_ID);
    setShowToast();
    setShowModal(false);
   emailjs
      .send(
       "service_upoilro",
        "template_4boev88",
        data,
       "VUn8RzMdyL_5aaWCz"
      )
      .then(
        
        (result) => {
         
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          alert(error)
        }
      );
    // const res = await axios.post('http://localhost:3000/vigil/add_user', {name,email,phone})
    // console.log(res);
      }
  }
  return (
    <div className={style.serviceModalContainer} >
      <div className='container' >
          <div class="content" style={{overflowY:"scroll",maxHeight:"80vh"}}>
          <ol type="1" style={{color:"#DDE6ED"}}>
    <li ><strong style={{color:'white',marginBottom:'2px'}}>1. Acceptance of Terms:</strong> By accessing and using this website, you agree to be bound by the following terms and conditions. If you do not agree with these terms, please do not use the website.</li>
    <br/>
    <li><strong style={{color:'white'}}>2. Course Content:</strong> The cybersecurity course content provided on this website is for informational and educational purposes only. The course materials are not intended to provide professional advice or guarantee any specific results.</li>
    <br/>
    <li><strong style={{color:'white'}}>3. Intellectual Property:</strong> All course materials, including text, images, videos, and any other content, are the property of the website owner and are protected by copyright laws. Unauthorized use or distribution of these materials is strictly prohibited.</li>
    <br/>
    <li><strong style={{color:'white'}}>4. Registration and Payment:</strong> To enroll in the cybersecurity course, you must complete the registration process and submit the required payment. Payment details and instructions will be provided during the registration process.</li>
    <br/>
    <li><strong style={{color:'white'}}>5. Access and Use:</strong> Upon successful registration and payment, you will be granted access to the online course materials for a specified duration. You are responsible for maintaining the confidentiality of your login credentials and ensuring that they are not shared with unauthorized individuals.</li>
    <br/>
  </ol>
    </div>
    
    <div className={style.btnContainer} style={{Maxwidth:"100%",display:"flex",alignItems:"center",textAlign:"center",justifyContent:'center',display:"hidden"}} >
     
      {/* <div
       
       className={style.btn}
       style={{
         textAlign:"center",
         
       }}
     >
       Accept
     </div> */}
  
       
      </div>
    </div>
    </div>
  );
};

export const Terms = () => {
  const [selectedService, setSelectedService] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const notify = () =>
    toast.success('Registration Succcess!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: '#1c1e2e', color: '#fff' },
    });
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className={style.servicesContainer}>
      {/* Section 1 */}
      <ScrollTop />
      <div className={style.section1}>
        <div className={style.title} style={{
          fontSize:"2.5rem",
          marginTop:"-10px"
        }}>
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.leftFloatingEle}
          />
         Terms and Conditions
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.rightFloatingEle}
          />
        </div>
     
      </div>
   
      {/* Section 2 */}
      {/* Section 3 */}
      <div className={style.modal} style={{display:"flex",justifyContent:"center"}}>
      
          <ServiceModal
            showModal={showModal}
            setShowModal={setShowModal}
            setShowToast={() => notify()}
          />
    
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: '50px' }}
      />
    </div>
  );
};
