import React, { useState } from 'react'
import Lottie from 'lottie-react'

import { SurveyQuestion } from '../../Pages/Home/home.data'
import style from './style.module.scss'
import checkIcon from './check.json'
import individualQuestionList from '../constants/individualQuestionList'
import orgQuestionList from '../constants/orgQuestionList'

const Questions = ({ options, step, handleChange }) => {
  const [option1,SetOption1]=useState("");
  return (
    <div style={{ color: '#fff' }}>
      <br/>
          
     {/* <input type="radio"  style={{visibility:"hidden"}} checked="true" name='anc'/> */}
      {options?.map((option,_id) => (
        <div
          style={{
            height: '50px',
            backgroundColor: '#2C2E41',
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            margin: '5px 0',
          }}
        >
          
          <input
            className={style.customRadio}
            type="radio"
            checked={option1==option.name}
            name={option.name}
            // value={option.weight}
            value={option.weight}
            onClick={(e) =>{ 
              console.log(option.name);
              handleChange(e.target.value);
              SetOption1(option.name);
            console.log(e.target.value);
            console.log("acb");
            }}
          />
          <label for={option.weight}>{option.optionName}</label>
        </div>
      ))}
    </div>
  )
}
var surveys=[];
const FormSteps = (step, setStep, setSurveyAnswer, surveyAnswer) => {
   surveys =  SurveyQuestion.map(que => (
    <>
      <div style={{ height: '50px', color: '#fff', padding: '20px' }}>
        {que.question}
      </div> 
 <Questions
        options={que.options}
        step={step}
        handleChange={answer => {
          if (surveyAnswer.length < step) {
            setSurveyAnswer(surveyAnswer.push(answer))
          } else {
            surveyAnswer[step] = answer
            setSurveyAnswer(surveyAnswer)
          }
        }}
      />
    </>
  ))
  var indiquestion=[];
  if(surveyAnswer[0]==='Individual'){
    console.log(surveyAnswer);
    indiquestion= individualQuestionList.question;
    surveys=indiquestion.map(e => (
      <>
        <div style={{ height: '50px', color: '#fff', padding: '20px' }}>
          {e.question}
        </div>
        <Questions
          options={e.options}
          step={step}
          handleChange={answer => {
            console.log(answer);
            if (surveyAnswer.length < step) {
              setSurveyAnswer(surveyAnswer.push(answer))
            } else {
              surveyAnswer[step] = answer
              setSurveyAnswer(surveyAnswer)
            }
          }}
        />
      </>
    )) 
  }
  
  else if(surveyAnswer[0]==='Organization'){
    console.log(surveyAnswer);
    surveys = orgQuestionList.map(e => (
      <>
        <div style={{ height: '50px', color: '#fff', padding: '20px',marginBottom:"3%",wordWrap:"break-word" }}>
          {e.question}
        </div>
        <Questions
          options={e.options}
          step={step}
          handleChange={answer => {
            if (surveyAnswer.length < step) {
              setSurveyAnswer(surveyAnswer.push(answer))
            } else {
              surveyAnswer[step] = answer
              setSurveyAnswer(surveyAnswer)
            }
          }}
        />
      </>
    ))
  }
let sum=0;
let msg='';
  if(surveyAnswer[0]==='Individual'){
   for(let index=1;index<6;index++){
    sum=sum+parseInt(surveyAnswer[index]);
   } 
   if(sum==2){
msg="You Don't Need Cyber Security";
   }
   else if(sum>=8){
    msg="You are in Extreme Need of Cyber Security";
   }
   else if(sum==5){
    msg="You Need A Bit of CyberSecuriy Awareness";
   }
   else{
    msg="You need some amount of cybersecurity in some domains ";
   }
  }
  if(surveyAnswer[0]==='Organization'){
    for(let index=1;index<6;index++){
      sum=sum+parseInt(surveyAnswer[index]);
     } 
     if(sum<5){
      msg="You Don't Need Cyber Security"
     }
     else if(sum==5){
      msg="You can do with a little bit of cyber security";
     }
     else if(sum>5&&sum<=8){
      msg="You Need some amout of Cyber Security in some domains";
     }
     else if(sum>8){
      msg="You Are In Extreme Need of Cyber Security";
     }
  }

  surveys.push(
    <div style={{textAlign:"center",color:"white"}}>
      <br/>
      <b style={{color:"white"}}>Survey Completed</b>
      <br/>
      <b style={{color:"#ff7c1f"}}>Based on the Survey :</b>
      <p>{msg}</p>
      <Lottie

        loop={false}
        animationData={checkIcon}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        style={{
          height: 200,
          width: 200,
          marginLeft:"25%",
          marginRight:"25%"
        }}
      />
      
    </div>
  )
  return surveys
}
export default FormSteps
