import React, { useState } from 'react';
import { quiz } from '../../APIs/api';


import FormSteps from './FormSteps';
import style from './style.module.scss';

export const Survey = ({ close }) => {
  let [step, setStep] = useState(0);
  const [surveyAnswer, setSurveyAnswer] = useState([]);
  const[ individualQuestionList, setIndividualQuestionList] = useState(false)
 const[ orgQuestionList, setOrgQuestionList] = useState(false);
  let steps = FormSteps(step, setStep, setSurveyAnswer, surveyAnswer);
 console.log(steps);
  return (
    <div>
      {/* <h4 style={{ textAlign: 'center', color: '#fff' }}>
        Step {step + 1}/{steps.length}
      </h4> */}
      <div>{steps[`${step}`]}</div>
      <div className={style.surveyBtn}>
        {steps.length - 1 > step ? (
          <>
            {step > 0 && (
              <div onClick={() => {
                // if(surveyAnswer.length===step+1){
                  
                //   setStep(step-1);
                // }
                // else{
                //   setStep(step - 1);
                // }
               close();
                
                
              }} className={style.btn}>
                close
              </div>
            )}

            <div
              onClick={() => {
                quiz();
                if(surveyAnswer.length===(step+1)){
                  if(step==1&&surveyAnswer[0]==='Individual'&&surveyAnswer[1]==2){
                    
                    surveyAnswer.push(0);
                    setStep(step+2);
                  }
                  else if(step==2&&surveyAnswer[0]==='Individual'&&surveyAnswer[2]==2){
                    surveyAnswer.push(0);
                    setStep(step+2);
                  }
                  else if(step==2&&surveyAnswer[0]==='Individual'&&surveyAnswer[2]==1){
                    
                    for (let index = 0; index < 3; index++) {
                      surveyAnswer.push(0);
                      
                    }
                    setStep(step+4);
                  }
                  else{
                    setStep(step + 1);
                  }
                 
                }
               
                console.log(step);
              }}
              className={style.btn}
            >
              Next
            </div>
          </>
        ) : (
          <div className={style.btn} onClick={close}>
            Close
          </div>
        )}
      </div>
    </div>
  );
};
