import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Home, About, Services, Courses, Contact } from './Pages';
import {Basic} from "./Pages/BasicComputing/index";
import {Terms} from "./Pages/Terms/index";
import { Privacy } from './Pages/PrivacyPolicy';
import { Refund } from './Pages/Refund';
import { Navbar, Footer } from './Components';

import a3 from './a3.png';
import './app.scss';
import { Modal } from './Components';
export const bubbleParam = {
  particles: {
    number: {
      value: 10,
      density: {
        enable: true,
        value_area:1000
      },
    },
    size: {
      value: 12,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out',
    },
    color: {
      value: '#ff7c1f',
    },
    shape: {
      type: ['image'],
      image: [
        {
          src: a3,
          height: 20,
          width: 20,
        },
      ],
    },
  },

  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: true,
        mode: 'repulse',
      },
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 1,
        enable: true,
      },
      repulse: {
        distance: 400,
        duration: 4,
      },
    },
  },
};

function App() {
  const [show,SetShow]=useState(false);
  const [nav,SetNav]=useState("default")
  useEffect(()=>{
    setTimeout(()=>{
     
      SetNav("none");
    },1500)
    
  },[])

  return (
    <BrowserRouter>
     <div style={{background:"gold",height:"auto",width:"100vw",color:"black",fontWeight:"400",fontFamily:" 'Changa', sans-serif",textAlign:"center"}}>
     VCF received awarded most innovative Cyber Security business in South Asia 2022 & Cyber Security Consulting Firm of India 2023 by Wealth & Finance Int  <a target="_blank" style={{textDecoration:"none"}}href='https://www.wealthandfinance-news.com/winners/vigilante-cyber-forces-2/'><button style={{backgroundColor:"#FF7C1F",width:"100px",borderRadius:"20px",color:"white",cursor:"pointer",border:"none",padding:"3px",boxSizing:"border-box",marginLeft:"2px",marginBottom:"3px"}}>Know More</button></a></div>
     
      <Navbar />

      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/services" exact>
          <Services />
        </Route>
        <Route path="/courses/basic" exact>
          <Basic/>
        </Route>
        <Route path="/courses" exact>
          <Courses />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/terms" exact>
          <Terms />
        </Route>
        <Route path="/privacy-policy">
          <Privacy/>
        </Route>
        <Route path="/refund">
          <Refund/>
        </Route>
      </Switch>
      <Footer />
      <Modal open={show} style={{marginTop:"300px",paddingBottom:"100px",height:'auto'}} >

    <img src="/VCF_Popup.jpg" style={{height:"100%",width:"100%"}} />
    <br/>
    <div style={{display:'flex',justifyContent:'flex-end',flexDirection:'row',marginRight:'30px',gap:'15px',backgroundColor:"#232537",width:'100%'}}>
    <button style={{width:'80px',backgroundColor:'#FF7C1F',border:'none',borderRadius:'15px',color:'white',cursor:'pointer'}}onClick={()=>{
      SetShow(false);
      SetNav("");
    } }>Close</button>
   <a style={{textDecoration:'none'}} target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSebhO5uM8KcgdYByHd7Aqq1K89o_qEx2jSgymOwhXbj7ClkTQ/viewform"><button style={{width:'100px',backgroundColor:'#FF7C1F',border:'none',borderRadius:'15px',color:'white',cursor:'pointer'}}>Register Now</button></a> 
    <br/>
    </div>
   

</Modal>
    </BrowserRouter>
  );
}

export default App;
