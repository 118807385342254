import CybersecurityAwareness from '../../assets/images/cyberawareness.jpg';
import CareerOpportunities from '../../assets/images/Career_Opportunities.jpg';
import CND from '../../assets/images/CND.jpg';
import CHFI from '../../assets/images/CHFI.jpg';
import CSA from '../../assets/images/CSA.jpg';
import CEH from '../../assets/images/CEH.jpg';
import BCSS from '../../assets/images/BCSS.jpg';

export const COURSES = [
  {
    title: 'Cyber Security Awareness – Webinar/Seminar',
    img: CybersecurityAwareness,
    desc: 'Become aware of the cyber threats prevalent today and be safe in the cyber world. With a combination of real-life scenarios and demos, VCF brings to you a wholesome experience of the world of Cyber Security.',
    duration: '1-1.5 Hrs.',
    isCertificateProvided: false,
  },
  {
    title: 'Career Opportunities in Cyber Security – Webinar/Seminar',
    img: CareerOpportunities,
    desc: 'Interested in building a career in Cyber Security? Get to know how to start on the career path from the experts themselves. Learn about the skills, certifications needed for a career in cybersecurity along with the scope in its various domains in the industry and build a strong career in cybersecurity.',
    duration: '1-1.5 Hrs.',
    isCertificateProvided: false,
  },
  {
    title: 'Basic Computing & System Security – Training Course',
    img: BCSS,
    desc: 'Learn the basics of effectively managing computers and using them securely in the cyber space. Learn how to secure personal data and digital devices in the cyber world. \nTo suit the needs of all, we present two variations of this course – \n α Course – Extremely useful for students and working professionals, especially in the IT sector. \nβ Course - Extremely useful for all age groups who use a computer and mobile phone. ',
    duration: '7 Days | 1-1.5 Hrs. per Day',
    isCertificateProvided: false,
  },
];

export const EC_COUNCIL = [
  {
    title: 'Certified Network Defender | CND',
    img: CND,
    desc: 'Earn the top global certification in network security and management. From basics to advanced, gain expertise in all aspects of network security to efficiently manage the IT infrastructure of organizations.',
    duration: '2-3 Months',
    content: [
      'Network Attacks and Defense Strategies',
      'Administrative Network Security',
      'Technical Network Security',
      'Network Perimeter Security',
      'Endpoint Security-Windows Systems',
      'Endpoint Security-Linux Systems',
      'Endpoint Security- Mobile Devices',
      'Endpoint Security-IoT Devices',
      'Administrative Application Security',
      'Data Security',
      'Enterprise Virtual Network Security',
      'Enterprise Cloud Network Security',
      'Enterprise Wireless Network Security',
      'Network Traffic Monitoring and Analysis',
      'Network Logs Monitoring and Analysis',
      'Incident Response and Forensic Investigation',
      'Business Continuity and Disaster Recovery',
      'Risk Anticipation with Risk Management',
      'Threat Assessment with Attack Surface Analysis',
      'Threat Prediction with Cyber Threat Intelligence',
    ],
    isCertificateProvided: false,
    certificationKit: [
      'Exam Voucher',
      'Certification',
      'Book Set',
      'Tools Set',
    ],
  },

  {
    title: 'Certified Ethical Hacker | CEH',
    img: CEH,
    desc: 'Become the best ethical hacker by gaining the most popular certification in the cybersecurity industry.',
    duration: '2-3 Months',
    content: [
      'Introduction',
      'Footprinting & Reconnaissance',
      'Scanning ',
      'Enumeration',
      'Vulnerability Assessment',
      'System Hacking',
      'Malware',
      'Sniffing',
      'Social Engineering',
      'Denial of Service (DoS)',
      'Session Hijacking',
      'IDS/IPS, Firewalls & Honeypots',
      'Web Server & Web Application Hacking',
      'Wireless Network Hacking',
      'Mobile Platform Hacking ',
      'Android Application Hacking',
      'IoT Hacking',
      'Cloud Computing',
      'Cryptographic Concepts',
    ],
    isCertificateProvided: false,
    certificationKit: [
      'Exam Voucher',
      'Certification',
      'Book Set',
      'Tools Set',
    ],
  },

  {
    title: 'Computer Hacking Forensic Investigator | CHFI',
    img: CHFI,
    desc: 'Study cybercrime investigation - the tools, the techniques, the tricks. Master the art of catching cybercriminals by becoming an expert in all domains of digital forensics.',
    duration: '2-3 Months',
    content: [
      'Computer Forensics in Today’s World',
      'Computer Forensics Investigation Process',
      'Understanding Hard Disks and File Systems',
      'Data Acquisition and Duplication',
      'Defeating Anti-Forensics Techniques',
      'Windows Forensics',
      'Linux and Mac Forensics',
      'Network Forensics',
      'Investigating Web Attacks',
      'Dark Web Forensics',
      'Database Forensics',
      'Cloud Forensics',
      'Investigating Email Crimes',
      'Malware Forensics',
      'Mobile Forensics',
      'IoT Forensics',
    ],
    isCertificateProvided: false,
    certificationKit: [
      'Exam Voucher',
      'Certification',
      'Book Set',
      'Tools Set',
    ],
  },

  {
    title: 'Certified SOC Analyst | CSA',
    img: CSA,
    desc: 'From basics to advanced, gain expertise in all aspects of network security to efficiently manage the IT infrastructure of organizations. Become a SOC expert with the CSA certification.',
    duration: '1-2 Months',
    content: [
      'Security Operations and Management',
      'Understanding Cyber Threats, IoCs, and Attack Methodology',
      'Incidents, Events, and Logging',
      'Incident Detection with Security Information and Event Management (SIEM)',
      'Enhanced Incident Detection with Threat Intelligence',
      'Incident Response',
    ],
    isCertificateProvided: false,
    certificationKit: [
      'Exam Voucher',
      'Certification',
      'Book Set',
      'Tools Set',
    ],
  },
];
