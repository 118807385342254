import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'email-validator';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import floatingElement from '../../assets/images/circuit.png';
import { SERVICES } from './services.data';
import { Modal } from '../../Components';

import { scrollToTop } from '../../utils/scrollToTop';
import { ScrollTop } from '../../Components/ScrollTop';
import axios from "axios";
import emailjs from "@emailjs/browser";
export const ServiceModal = ({ showModal, setShowModal, setShowToast }) => {

  const [input, setInput] = useState({
    name: '',
    email: '',
    phone: ''
  })

  const [enquireType,setEnquireType]=('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const checkData = () => {
    if (!name || !validate(email)|| phone.length!==10) {
      return false;
      
    }
    return true;
  };

console.log(name,email,phone)
  const handleClick=async(event)=>{
    event.preventDefault();
    var data={
      message_type:"Service Enquiry",
      user_name:name,
      user_email:email,
      user_enquiry:showModal,
      user_phone:phone,
      message:"This message is from service Enquriy Page"
    }
    if(!checkData()){
      if(phone.length!==10){
        alert("please enter a 10 digit Number");
      }
      else if (!name){
        alert("please enter name");
      }
      else if(!validate(email)){
        alert("Please enter a valid email");
      }
    }
    else{

    console.log(process.env.REACT_APP_SERVICE_ID);
    setShowToast();
    setShowModal(false);
   emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        data,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        
        (result) => {
         
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          alert(error)
        }
      );
    // const res = await axios.post('http://localhost:3000/vigil/add_user', {name,email,phone})
    // console.log(res);
      }
  }
  return (
    <div className={style.serviceModalContainer}>
      <div className={style.titleContainer}>
        <div className={style.modalTitle}>{showModal}</div>
        <div className={style.cancleBtn} onClick={() => setShowModal(false)}>
          <FontAwesomeIcon icon={faTimes} color="#414350" />
        </div>
      </div>
      <div className={style.subContent}>
        <div className={style.title}>Know More About the Service</div>
        <div className={style.desc}>
          Don't worry we wont spam you with emails. Please Give us your details
          and we would contact and assist you.
        </div>
      </div>
      <div className={style.formContainer}>
        <div className={style.inputContainer}>
          <label htmlFor="name">Name*</label>
          <br />
          <input
          name='name'
            type="text"
            id="name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            required
            maxlength="20"
          />
        </div>
        <div className={style.inputContainer}>
          <label htmlFor="email">Email address*</label>
          <br />
          <input
          name='email'
            type="email"
            id="email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            required
          />
        </div>
        <div className={style.inputContainer}>
                  <label htmlFor="phone">Phone number*</label>
                  <br />
                  <input
                  name='phone'
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={(e)=>setPhone(e.target.value)}
                    required
                    maxlength="10"
                  />
                </div>
      </div>
      <div className={style.btnContainer}>
        <div
          onClick={handleClick}
          className={style.btn}
        >
          Submit
        </div>
      </div>
    </div>
  );
};

export const Services = () => {
  const [selectedService, setSelectedService] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const notify = () =>
    toast.success('We will contact you soon!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: '#1c1e2e', color: '#fff' },
    });
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className={style.servicesContainer}>
      {/* Section 1 */}
      <ScrollTop />
      <div className={style.section1}>
        <div className={style.title}>
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.leftFloatingEle}
          />
          Services
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.rightFloatingEle}
          />
        </div>
      </div>
      {/* Section 2 */}
      <div className={style.section2}>
        <div className={style.serviceBtnContainer}>
          {SERVICES.map((service, index) => (
            
            <div
              onClick={() => {setSelectedService(index)
                window.scrollBy({
                  top: 300,
                  behavior: 'smooth'
                });
              }}
              className={`${style.btns} ${
                index === selectedService ? style.selected : null
              }`}
            >
              {service.serviceName}
            </div>
          ))}
        </div>
       
        <div className={style.descService} id="servicesContent">
        <h2>{SERVICES[selectedService]?.serviceName}</h2>
        <br></br>
          {SERVICES[selectedService]?.desc}
        </div>
      </div>
      {/* Section 3 */}
      <div className={style.section3}>
        {SERVICES[selectedService]?.services?.map((service) => (
          <div className={style.card}>
            <div className={style.title}>{service.name}</div>
            <div className={style.image}>
              <img src={service.img} alt="service" />
            </div>
            <div className={style.content}>{service.desc}</div>
            <div className={style.btnContainer}>
              <div
                onClick={() => {setShowModal(service.name)
                
                }}
                className={style.btn}
              >
                Enquire
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={style.modal}>
        <Modal open={showModal}>
          <ServiceModal
            showModal={showModal}
            setShowModal={setShowModal}
            setShowToast={() => notify()}
          />
        </Modal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: '50px' }}
      />
    </div>
  );
};
