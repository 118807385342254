import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import style from './style.module.scss';
import mailIcon from '../../assets/images/mail.png';
import callIcon from '../../assets/images/call.png';
import linkedin from '../../assets/images/linkedin.png';
import instagram from '../../assets/images/instagram.png';
import facebook from '../../assets/images/facebook.png';
import youtube from '../../assets/images/youtube.png';
import location from '../../assets/images/location.png';
const date=new Date();
export const Footer = () => {
  return (
    <div className={style.footerContainer}>
      <div className={style.companyInfo}>
        <div className={style.companyContainer}>
          <div className={style.title}>Our Company</div>
          <div className={style.link}>
            <Link to="/">Home</Link>
          </div>
          <div className={style.link}>
            <Link to="/about">About Us</Link>
          </div>
          <div className={style.link}>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className={style.link}>
            <Link to="/services">Services</Link>
          </div>
          <div className={style.link}>
            <Link to="/courses">Learn</Link>
          </div>
          
        </div>
        <div className={style.contactInfoContainer}>
          <div className={style.title}>Contact Info</div>
          <div className={style.mail}>
            <img src={mailIcon} alt="mail" />
            contact@vigilantecyberforces.com | vigilante.de.jure@gmail.com
          </div>
          <div className={style.call}>
            <img src={callIcon} alt="phone number" />
            (+91) 73853 69311 | 82088 22572
          </div>
          <div className={style.address}>
            <img src={location} alt="location" />
            118-Ground Floor, Parijat Flats, Shivaji Nagar, Nagpur-440010
          </div>
        </div>
        <div className={style.socialContainer}>
          <div className={style.title}>Follow us</div>
          <div className={style.icons}>
            <a
              href="https://www.linkedin.com/company/vigilante-cyber-forces/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>

            <a
              href="https://www.instagram.com/vigilante_secure/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
              href="https://www.facebook.com/vigilantesecure/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCy5Ex3pHv2d3ZUeG_CKJ-Gw"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="youtube" />
            </a>
            <a
              href="https://www.twitter.com/VCFCyberSec"
              target="_blank"
              rel="noopener noreferrer"
            >
              
              <FontAwesomeIcon icon={faTwitter} style={{
                color:"white",
                transform:"Scale(1.2)"
              }} />
            </a>
          </div>
        </div>
        
      </div>
      <div className={`${style.fcontent}`}>
            We are now listed on <a href="https://www.designrush.com/"><span className={`${style.underline}`}>DesignRush</span></a>
      </div>
      <div className={`${style.copyRight} ${style.companyInfo}`}>© {date.getFullYear()} VCF Cyber Solutions Pvt. Ltd.| All rights reserved.
      <div className={`${style.link}`}>
        <Link to="/terms">Terms and Conditions | </Link>
      
        <Link to="/privacy-policy">Privacy Policy | </Link>
      
        <Link to="/refund" >Refund Policy</Link>
      </div>
      </div>


      
          {/* <div className={style.link}>
            <Link to="/terms">Terms and Conditions</Link>
          </div>
          <div className={style.link}>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div className={style.link}>
            <Link to="/refund">Refund Policy</Link>
          </div> */}
      
      
      
      
    </div>
  );
};
