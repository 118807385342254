import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'email-validator';

import { sendEmail } from '../../APIs/api';
import style from './style.module.scss';
import phoneImg from '../../assets/images/phone.png';
import emailImg from '../../assets/images/email.png';
import { ScrollTop } from '../../Components/ScrollTop';

export const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country_code, setcountry_code] = useState('');
  const [phone, setPhone] = useState('');
  const [help, sethelp] = useState('');

  const checkDetails = () => {
    if (!name || !validate(email) || !country_code || !phone || !help) {
      return false;
    }
    return true;
  };

  const notify = () =>
    toast.success('We will contact you soon!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: '#1c1e2e', color: '#fff' },
    });
  return (
    <div className={style.contactContainer}>
      <ScrollTop />
      <div className={style.section1}>
        <div className={style.bg}></div>
        <div className={style.emptyBox}></div>
        <div className={style.contactFormContainer}>
          <div className={style.title}>Contact Us
         
          </div>
          <p style={{
                    color:"#fff",
                    fontSize: "1rem",
                    fontWeight: "300",
          }}>Tell us About your requirements</p>
          <div className={style.formContainer}>
            <div className={style.inputContainer}>
              <label htmlFor="name">Name</label>
              <br />
              <input
              style={{color:"white"}}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={style.inputContainer}>
              <label htmlFor="name">Email</label>
              <br />
              <input
              style={{color:"white"}}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={style.inputContainer}>
              
              </div>
            <div className={style.inputContainer}>
            <label htmlFor="name">Phone Number (With Country Code)</label>
              <br/>
              <span style={{display: 'inline-block', width: '13px', height: '13px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" fill='white'/></svg>
              </span>
              <input
                type="number"
                style={{width:"30px", color:"white"}}
                name={country_code}
                onChange={(e) => setcountry_code(e.target.value)}
              />
              <span>           </span>
              
              <input
                type="text"
                style={{width:"250px", color:"white"}}
                name={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className={style.inputContainer}>
              <label htmlFor="name">How can we help?</label>
              <br/>
              <input
                type="text"
                style={{color:"white"}}
                name={help}
                onChange={(e) => sethelp(e.target.value)}
              />
            </div>
          </div>
          <div className={style.btnContainer}>
            <div
              onClick={() => {
                
                  sendEmail(name, email, phone, help,"contact Form");
                
              }}
              className={style.btn}
            >
              Contact Us
              
            </div>
          </div>
        </div>
      </div>
      <div className={style.section2}>
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.4117275260646!2d79.05344861476526!3d21.136006985940348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c113ede8def1%3A0xddee85234711ddb7!2sVigilante%20Cyber%20Forces!5e0!3m2!1sen!2sin!4v1633287280407!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className={style.section3}>
        <div className={style.title}>Call Us</div>
        <div className={style.container}>
          <div className={style.innerContainer}>
            <img src={phoneImg} alt="phone" />
          </div>
          <div className={style.detail}>
            <span>(+91) 73853 69311 | 82088 22572</span>
            <span>Don't hesitate to contact us!</span>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.innerContainer}>
            <img src={emailImg} alt="email" />
          </div>
          <div className={style.detail}>
            <span>
              vigilante.de.jure@gmail.com | contact@vigilantecyberforces.com
            </span>
            <span>If you have any queries don't hesitate to mail us</span>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: '50px' }}
      />
    </div>
  );
};
