import emailjs from "@emailjs/browser";
import { validate } from 'email-validator';
import { ToastContainer, toast } from 'react-toastify';
const notify = () =>
toast.success('We will contact you soon!', {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  style: { background: '#1c1e2e', color: '#fff' },
});
export const sendEmail = async (name, email, phone, message, type) => {
    var data={
        message_type:type,
        user_name:name,
        user_email:email,
        user_phone:phone,
        message:message
    }
    const checkData = () => {
      if (!name || !validate(email)|| phone.length!==10) {
        return false;
        
      }
      return true;
    };
    if(!checkData()){
      if(!name){
        alert("Plese Enter Name");
      }
      else if (!validate(email)){
        alert("please enter a valid email");
      }
      else if(phone.length!==10){
        alert("Please enter a 10 digit contact number");
      }
    }
    else{
      notify();
      emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        data,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        
        (result) => {
          console.log("then")
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          alert("There was some error")
        }
      );
    }
};

export const quiz = async (type, id) => {
  try {
    const row = await fetch('/vigil/users', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    const data = await row.json();
    console.log('CHECK', data);
    return true;
  } catch (e) {
    return false;
  }
};
