import React, { useState, useRef } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import style from './style.module.scss';
import logo from '../../assets/images/logo.png';

export const Navbar = () => {
  const [showMobileMenu, setMobileMenu] = useState(false);

  const navRef = useRef(null);
  const handleNavClick = (e) => {
    if (navRef.current) {
      const activeNavs = navRef.current.getElementsByClassName(style.activeNav);
      if (activeNavs?.length !== 0) {
        activeNavs[0].classList.remove(style.activeNav);
      }
    }
    e.currentTarget.classList.add(style.activeNav);
  };
 

  return (
    <div className={style.navbarContainer}>
      <Link to="/">
      <div className={style.logo}  >
        <img src={logo} alt="logo" />
      </div>
      </Link>
      <div ref={navRef} className={style.navs}>
        <Link to="/">
          <div
            className={`${style.nav} ${
              useRouteMatch('/')?.isExact && style.activeNav
            }`}
            onClick={handleNavClick}
          >
            Home
          </div>
        </Link>
        <Link to="/services">
          <div
            className={`${style.nav} ${
              useRouteMatch('/services')?.isExact && style.activeNav
            }`}
            onClick={handleNavClick}
          >
            Services
          </div>
        </Link>
        <Link to="/courses">
          <div
            className={`${style.nav} ${
              useRouteMatch('/courses')?.isExact && style.activeNav
            }`}
            onClick={handleNavClick}
          >
            Learn
          </div>
        </Link>

        <Link to="/about">
          <div
            className={`${style.nav} ${
              useRouteMatch('/about')?.isExact && style.activeNav
            }`}
            onClick={handleNavClick}
          >
            About
          </div>
        </Link>

        <Link to="/contact">
          <div
            className={`${style.nav} ${
              useRouteMatch('/contact')?.isExact && style.activeNav
            }`}
            onClick={handleNavClick}
          >
            Contact
          </div>
        </Link>
      </div>
      <div
        className={style.bars}
        onClick={() => setMobileMenu(!showMobileMenu)}
      >
        <FontAwesomeIcon icon={faBars} color="#fff" />
      </div>
      {showMobileMenu && (
        <div className={style.mobileNavs}>
          <Link to="/" onClick={() => setMobileMenu(false)}>
            <div className={style.nav}>Home</div>
          </Link>
          <Link to="/services" onClick={() => setMobileMenu(false)}>
            <div className={style.nav}>Services</div>
          </Link>
          <Link to="/courses" onClick={() => setMobileMenu(false)}>
            <div className={style.nav}>Courses</div>
          </Link>
          <Link to="/about" onClick={() => setMobileMenu(false)}>
            <div className={style.nav}>About</div>
          </Link>
          <Link to="/contact" onClick={() => setMobileMenu(false)}>
            <div className={style.nav}>Contact</div>
          </Link>
        </div>
      )}
    </div>
  );
};
