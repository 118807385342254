import React from 'react';
import ScrollToTop from 'react-scroll-to-top';

import style from './style.module.scss';
import up from '../../assets/images/up.png';

export const ScrollTop = () => {
  return (
    <ScrollToTop
      smooth
      component={
        <div className={style.scrollToTop}>
          <img src={up} alt="up" />
        </div>
      }
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '50px',
        height: '50px',
      }}
    />
  );
};
