import React, { useEffect, useState } from "react";

import style from "./style.module.scss";
import floatingElement from "../../assets/images/circuit.png";
import founder from "../../assets/images/founder.jpg";
import COO from "../../assets/images/COO.jpg";
import linkedin from "../../assets/images/linkedin.png";
import instagram from "../../assets/images/instagram.png";
import { SECURE_INFO } from "./about.data";
import { ScrollTop } from "../../Components/ScrollTop";
import WhyUS from "./WhyUS";
export const About = () => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(0);
    setInterval(() => {
      setSelected((prev) => {
        if (prev < SECURE_INFO.length - 1) {
          return prev + 1;
        } else {
          return 0;
        }
      });
    }, 3000);
    return clearInterval();
  }, []);

  return (
    <div className={style.aboutContainer}>
      {/* Section 1 */}
      <ScrollTop />
      <div className={style.section1}>
        <div className={style.title}>
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.leftFloatingEle}
          />
          About Us
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.rightFloatingEle}
          />
        </div>
        <div className={style.desc}>
          We are a group of enthusiastic, certified individuals based in India
          working towards making this world cyber secure.
        </div>
        <div className={style.mission}>
          <div className={style.title}>Our Mission & Vision</div>
          <div className={style.desc}>
            Being a professional cybersecurity agency, our mission is to
            increase awareness about cybercrime and provide customer-centric and
            cost-efficient security services to both individuals and
            organizations.
          </div>
        </div>
        <div className={style.whyChoose}>
          <div className={style.title}>Why Choose Us?</div>
          <WhyUS/>
        </div>
      </div>

      {/* Section 2 */}
      <div className={style.section2}>
        <div className={style.title}>Our Values</div>
        <div className={style.btns}>
          {SECURE_INFO.map((data, index) => (
            <div
            onClick={()=>{setSelected(index)}}
              key={index}
              className={`${style.btn} ${
                selected === index ? style.pressed : null
              }`}
            >
              {data.initials}
            </div>
          ))}
        </div>
        <div className={style.detailBox}>
          <div className={style.imgbox}>
            <img src={SECURE_INFO[selected]?.img} alt="s" />
          </div>
          <div className={style.content}>
            <div className={style.title}>{SECURE_INFO[selected]?.title}</div>
            <div className={style.info}>{SECURE_INFO[selected]?.desc}</div>
          </div>
        </div>
        <div></div>
      </div>

      {/* Section 3 */}
      <div className={style.section3}>
        <div className={style.title}>Our Management</div>
        <div className={style.teamContainer}>
          <div className={style.personCard}>
            <img src={founder} alt="person" className={style.personImg} />
            <div className={style.personDetail}>
              <div className={style.name}>Isha Mujumdar </div>
              <div className={style.designation}>Founder & CEO</div>
              <div className={style.links}>
                <a
                  href="https://www.linkedin.com/in/isha-mujumdar-vcfmd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
              </div>
            </div>
          </div>

          <div className={style.personCard}>
            <img src={COO} alt="person" className={style.personImg} />
            <div className={style.personDetail}>
              <div className={style.name}>Vaibhav Malgewar </div>
              <div className={style.designation}>Co-Founder</div>
              <div className={style.links}>
                <a
                  href="https://www.linkedin.com/in/vaibhav-malgewar-b543b51ab/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
                <a
                  href="https://www.instagram.com/vaibhav_malgewar/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
