import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'email-validator';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import floatingElement from '../../assets/images/circuit.png';
import { SERVICES } from './services.data';
import { Modal } from '../../Components';

import { scrollToTop } from '../../utils/scrollToTop';
import { ScrollTop } from '../../Components/ScrollTop';
import axios from "axios";
import emailjs from "@emailjs/browser";
export const ServiceModal = ({ showModal, setShowModal, setShowToast }) => {

  const [input, setInput] = useState({
    name: '',
    email: '',
    phone: ''
  })

  const [enquireType,setEnquireType]=('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [Organization,setOrganization]=useState('');
  const checkData = () => {
    if (!name || !validate(email)|| phone.length!==10) {
      return false;
      
    }
    return true;
  };

console.log(name,email,phone)
  const handleClick=async(event)=>{
    event.preventDefault();
    var data={
      message_type:"Service Enquiry",
      from_name:name,
      from_email:email,
      course_name:"Basic Computing",
      from_phone:phone,
      from_org:Organization,
      message:"This message is from service Enquriy Page"
    }
    if(!checkData()){
      if(phone.length!==10){
        alert("please enter a 10 digit Number");
      }
      else if (!name){
        alert("please enter name");
      }
      else if(!validate(email)){
        alert("Please enter a valid email");
      }
    }
    else{

    console.log(process.env.REACT_APP_SERVICE_ID);
    setShowToast();
    setShowModal(false);
   emailjs
      .send(
       "service_upoilro",
        "template_4boev88",
        data,
       "VUn8RzMdyL_5aaWCz"
      )
      .then(
        
        (result) => {
         
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          alert(error)
        }
      );
    // const res = await axios.post('http://localhost:3000/vigil/add_user', {name,email,phone})
    // console.log(res);
      }
  }
  return (
    <div className={style.serviceModalContainer} >
      <div className='container' >
          <div class="content" style={{overflowY:"scroll",maxHeight:"80vh"}}>
          <ol type="1" style={{color:"#DDE6ED"}}>
    <li ><strong style={{color:'white',marginBottom:'2px'}}>1. Refunds:</strong> Refunds will be provided only in cases where the course has not been accessed or if a refund request is made within a specified period after enrollment. Refund eligibility and the refund process will be clearly communicated during the enrollment process.</li>
<br/>
    <li><strong style={{color:'white'}}>2. Cancellation:</strong> If you wish to cancel your enrollment, you must notify us within the specified cancellation period. Cancellation requests received after this period may not be honored, and no refunds will be provided.
</li>
<br/>

    <li><strong style={{color:'white'}}>3. Course Modification:</strong> We reserve the right to modify or discontinue the cybersecurity course, including course content, duration, and access, at any time without prior notice. In such cases, enrolled users will be notified, and suitable alternatives or refunds may be offered.
.</li>
<br/>

    
  </ol>
    </div>
    
    <div className={style.btnContainer} style={{Maxwidth:"100%",display:"flex",alignItems:"center",textAlign:"center",justifyContent:'center',display:"hidden"}} >
     
      {/* <div
       
       className={style.btn}
       style={{
         textAlign:"center",
         
       }}
     >
       Accept
     </div> */}
  
       
      </div>
    </div>
    </div>
  );
};

export const Refund = () => {
  const [selectedService, setSelectedService] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const notify = () =>
    toast.success('Registration Succcess!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: '#1c1e2e', color: '#fff' },
    });
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className={style.servicesContainer}>
      {/* Section 1 */}
      <ScrollTop />
      <div className={style.section1}>
        <div className={style.title} style={{
          fontSize:"2.5rem",
          marginTop:"-10px"
        }}>
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.leftFloatingEle}
          />
         Refund Policy
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.rightFloatingEle}
          />
        </div>
     
      </div>
   
      {/* Section 2 */}
      {/* Section 3 */}
      <div className={style.modal} style={{display:"flex",justifyContent:"center"}}>
      
          <ServiceModal
            showModal={showModal}
            setShowModal={setShowModal}
            setShowToast={() => notify()}
          />
    
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: '50px' }}
      />
    </div>
  );
};
