import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Card, BackShadowTitle, Modal, ScrollTop } from "../../Components";
import { cards } from "./home.data";

import style from "./style.module.scss";
import heroImg from "../../assets/images/hero-main.png";
import cyberSecImg from "../../assets/images/cyberSec.png";
import circuitImg from "../../assets/images/circuit.png";
import DFS from "../../assets/images/DFShape.png";
import VAPT from "../../assets/images/VAPT.png";
import ISAC from "../../assets/images/ISAC.png";
import WEB from "../../assets/images/WEB.png";
import client1 from "../../assets/1.png";
import client2 from "../../assets/2.png";
import client3 from "../../assets/3.png";
import client4 from "../../assets/4.png";
import client5 from "../../assets/5.png";
import client6 from "../../assets/6.png";
import client7 from "../../assets/7.png";
import client8 from "../../assets/8.png";
import client9 from "../../assets/9.png";
import client10 from "../../assets/10.png";
import client11 from "../../assets/11.png";
import client12 from "../../assets/12.png";
import client13 from "../../assets/13.png";
import client14 from "../../assets/14.png";
import client15 from "../../assets/15.png";
import client16 from "../../assets/16.png";
import client17 from "../../assets/17.png";
import { Survey } from "../../Components/Survey";
import Particles from "react-tsparticles";
import { ServiceModal } from "../Services";

export const Home = () => {
  const [showSurveyForm, setShowSurveyForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [Shadow, setShowShadow] = useState(false);
  useEffect(() => {
    if (window.matchMedia("(max-width: 700px)").matches) {
      setShowShadow(false);
      // alert("matches");
    } else {
      setShowShadow(true);
    }
  }, []);
  const notify = () =>
    toast.success("We will contact you soon!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: "#1c1e2e", color: "#fff" },
    });

  return (
    <>
      <div className={style.homeContainer}>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            zIndex: "-10",
          }}
        >
          <Particles
            id="tsparticles"
            height="100%"
            options={{
              fpsLimit: 100,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 1,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ff7c1f",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: false,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 2.5,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    value_area: 800,
                  },
                  value: 20,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 5,
                },
              },
              detectRetina: true,
            }}
          />
          );
        </div>
        <ScrollTop />
        {/* Section 1 */}
        <div className={style.heroContainer}>
          <div className={style.heroContentContainer}>
            <div className={style.mainTitleText}>
              <div className={style.taglineText}>Security </div>
              <div className={style.backText}>Security</div>
            </div>
            <div className={style.subtitle}>Before Everything Else</div>
            <div className={style.mobile_heroImg}>
              <img src={heroImg} alt="hero" />
            </div>
            <div className={style.primaryBtn}>
              <Link to="/services">
                <button className={style.btn}>Learn More</button>
              </Link>
            </div>
          </div>
          <div className={style.heroImageContainer}>
            <img src={heroImg} alt="hero" />
          </div>
        </div>

        {/* Section 2 */}
        <div className={style.section2}>
          <div className={style.heading}>Why Cyber Security?</div>
          <div className={style.cardContainer}>
            {cards.map((card, index) => (
              <Card img={card.img} desc={card.desc} title={card.title} />
            ))}
          </div>
        </div>

        {/* Section 3 */}
        <div className={style.section3}>
          <img
            className={style.floatingEle1}
            src={circuitImg}
            alt="floatingElement"
          />
          <img
            className={style.floatingEle2}
            src={circuitImg}
            alt="floatingElement"
          />
          {console.log("CHECK", window.innerWidth)}
          <div className={style.title}>
            <BackShadowTitle
              taglineText="CyberSec Elements"
              shadowText="CyberSec Elements"
              taglineTextStyle={{
                fontSize: window.innerWidth > 1500 ? "3rem" : "3rem",
              }}
              shadowTextStyle={{
                top: "-10vh",
                fontSize: window.innerWidth > 1500 ? undefined : "2.3rem",
              }}
              style={
                Shadow? {display:"initial"} : {display:"none"}
              }
            />
          </div>
          <div className={style.cyberSecEle}>
            <img src={cyberSecImg} alt="cyberSecElements" />
          </div>
        </div>

        {/* Section 4 */}
        <div className={style.section4}>
          <img
            className={style.floatingEle5}
            src={circuitImg}
            alt="floatingElement"
          />
          <div className={style.title}>
            Wondering if you need Cyber Security? Let’s Find Out!
          </div>
          <div className={style.subtitle}>
            Take this mini quiz and learn about your need of cybersecurity.{" "}
          </div>
          <div
            className={style.beginBtn}
            onClick={() => setShowSurveyForm(true)}
          >
            Begin
          </div>
          <Modal open={showSurveyForm}>
            <div>
              <Survey close={() => setShowSurveyForm(false)} />
            </div>
          </Modal>
        </div>

        {/* Section 5 */}
        <div className={style.section5}>
          <div className={style.title}>
            <BackShadowTitle
              taglineText="Take a look at our Clients"
              shadowText="Clients"
              taglineTextStyle={{
                fontWeight: "500",
                fontSize: window.innerWidth > 1500 ? "3rem" : "2rem",
              }}
              shadowTextStyle={{
                top: window.innerWidth > 1500 ? "-190%" : "-100%",
                fontSize: window.innerWidth > 1500 ? "10rem" : "3.5rem",
              }}
            />
          </div>

          <div className={style.carousel}>
            <div className={style.clientBanner}>
              <img src={client1} alt="client" />
              <img src={client2} alt="client" />
              <img src={client3} alt="client" />
              <img src={client4} alt="client" />
              <img src={client5} alt="client" />
              <img src={client6} alt="client" />
              <img src={client7} alt="client" />
              <img src={client8} alt="client" />
              <img src={client9} alt="client" />
              <img src={client10} alt="client" />
              <img src={client11} alt="client" />
              <img src={client12} alt="client" />
              <img src={client13} alt="client" />
              <img src={client14} alt="client" />
              <img src={client15} alt="client" />
              <img src={client16} alt="client" />
              <img src={client17} alt="client" />
              {/* <img src={client18} alt="client" /> */}
            </div>
            <div className={style.clientBanner}>
            <img src={client1} alt="client" />
              <img src={client2} alt="client" />
              <img src={client3} alt="client" />
              <img src={client4} alt="client" />
              <img src={client5} alt="client" />
              <img src={client6} alt="client" />
              <img src={client7} alt="client" />
              <img src={client8} alt="client" />
              <img src={client9} alt="client" />
              <img src={client10} alt="client" />
              <img src={client11} alt="client" />
              <img src={client12} alt="client" />
              <img src={client13} alt="client" />
              <img src={client14} alt="client" />
              <img src={client15} alt="client" />
              <img src={client16} alt="client" />
              <img src={client17} alt="client" />
              {/* <img src={client18} alt="client" /> */}
            </div>
            <div className={style.clientBanner}>
            <img src={client1} alt="client" />
              <img src={client2} alt="client" />
              <img src={client3} alt="client" />
              <img src={client4} alt="client" />
              <img src={client5} alt="client" />
              <img src={client6} alt="client" />
              <img src={client7} alt="client" />
              <img src={client8} alt="client" />
              <img src={client9} alt="client" />
              <img src={client10} alt="client" />
              <img src={client11} alt="client" />
              <img src={client12} alt="client" />
              <img src={client13} alt="client" />
              <img src={client14} alt="client" />
              <img src={client15} alt="client" />
              <img src={client16} alt="client" />
              <img src={client17} alt="client" />
              {/* <img src={client18} alt="client" /> */}
            </div>
            <div className={style.clientBanner}>
            <img src={client1} alt="client" />
              <img src={client2} alt="client" />
              <img src={client3} alt="client" />
              <img src={client4} alt="client" />
              <img src={client5} alt="client" />
              <img src={client6} alt="client" />
              <img src={client7} alt="client" />
              <img src={client8} alt="client" />
              <img src={client9} alt="client" />
              <img src={client10} alt="client" />
              <img src={client11} alt="client" />
              <img src={client12} alt="client" />
              <img src={client13} alt="client" />
              <img src={client14} alt="client" />
              <img src={client15} alt="client" />
              <img src={client16} alt="client" />
              <img src={client17} alt="client" />
              {/* <img src={client18} alt="client" /> */}
            </div>
          </div>
        </div>

        {/* Section 6 */}
        <div className={style.section6}>
          <div className={style.sectionTitle}>
            <BackShadowTitle
              taglineText="Our Services"
              shadowText="Services"
              taglineTextStyle={{
                fontSize: window.innerWidth > 1500 ? "3rem" : "2.5rem",
              }}
              shadowTextStyle={{
                top: window.innerWidth > 1500 ? "-150%" : "-10%",
                fontSize: window.innerWidth > 1500 ? undefined : "4.3rem",
              }}
            />
          </div>

          <div className={style.services}>
            <div className={style.service}>
              <div className={style.serviceImg}>
                <img src={VAPT} alt="services" />
              </div>
              <div className={style.content}>
                <div className={style.title}>
                  Vulnerability Assessment & Penetration Testing (VAPT)
                </div>
                <div className={style.desc} style={{fontFamily:" 'Changa', sans-serif"}}>
                  We uncover all loopholes existing in your network, website,
                  mobile and other applications, and assess the extent of threat
                  they pose to your organization by using a combination of
                  automated and manual testing procedures aimed at minimizing
                  the overall risk from cyber-attacks.
                  <br />
                  Here, Vulnerability Assessment (VA) is the process of finding
                  all possible vulnerabilities/loopholes/issues in your IT
                  infrastructure and Penetration Testing (PT) is the process of
                  assessing the impact of cyber-attacks by carrying out a number
                  of ethical hacking tests. This enables organizations to
                  recognize the security risk that exists in their IT
                  infrastructure and remove or mitigate it for a secure
                  environment.
                </div>
                <div className={style.serviceBtn} >
                  <div
                    className={style.btn}
                    onClick={() => setShowModal("Vulnerability Assessment & Penetration Testing (VAPT)")}
                  >
                    Enquire
                  </div>
                </div>
              </div>
            </div>

            <div className={`${style.service} ${style.reverse}`}>
              <div className={style.serviceImg}>
                <img src={ISAC} alt="services" />
              </div>
              <div className={style.content}>
                <div className={style.title}>
                  Information Security Auditing & Consultation
                </div>
                <div className={style.desc} style={{fontFamily:"'Changa', sans-serif"}}>
                  Make your organization a security stronghold by testing its
                  compliance with various global standards of information
                  security. We ensure total compliance with the required
                  security standards and guide through the complete process.
                </div>
                <div className={style.serviceBtn}>
                  <div
                    className={style.btn}
                    onClick={() => setShowModal("Information Security Auditing & Consultation")}
                  >
                    Enquire
                  </div>
                </div>
              </div>
            </div>

            <div className={style.service}>
              <div className={style.serviceImg}>
                <img src={WEB} alt="services" />
              </div>
              <div className={style.content}>
                <div className={style.title}>Web Design & Development</div>
                <div className={style.desc}>
                  Bring your products and services to customers through the
                  easiest and most popular channel – let us build a website for
                  you!
                </div>
                <div className={style.serviceBtn}>
                  <div
                    className={style.btn}
                    onClick={() => setShowModal("Web Design & Development")}
                  >
                    Enquire
                  </div>
                </div>
              </div>
            </div>

            <div className={`${style.service} ${style.reverse}`}>
              <div className={style.serviceImg}>
                <img src={DFS} alt="services" />
              </div>
              <div className={style.content}>
                <div className={style.title}>Digital Forensics Solutions</div>
                <div className={style.desc}>
                  Hassle-free deleted data recovery, investigation of hacked
                  devices, forensic clean-up of devices provided with total
                  confidentiality.
                </div>
                <div className={style.serviceBtn}>
                  <div
                    className={style.btn}
                    onClick={() => setShowModal("Digital Forensics Solutions")}
                  >
                    Enquire
                  </div>
                </div>
              </div>
            </div>
            
            <div className={style.showMore}>
              <Link to="/services">
                <div className={style.btn}>Show more</div>{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.modal}>
        <Modal open={showModal}>
          <ServiceModal
            showModal={showModal}
            setShowModal={setShowModal}
            setShowToast={() => notify()}
          />
        </Modal>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "50px" }}
      />
    </>
  );
};
