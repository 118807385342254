import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'email-validator';
import learn from "./learn.png";
import style from './style.module.scss';
import icon from './style.module.scss';
import floatingElement from '../../assets/images/circuit.png';
import ECCouncil from '../../assets/images/ECCouncil.png';
import { COURSES, EC_COUNCIL } from './courses.data';
import { sendEmail } from '../../APIs/api';
import { ScrollTop } from '../../Components/ScrollTop';
import circuitImg from '../../assets/images/circuit.png';


const CourseContent = ({ course, index }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div
      className={`${style.course} ${index % 2 === 0 ? style.reverse : null}`}
    >
      <div className={style.serviceImg}>
        <img src={course?.img} alt="services" />
      </div>
      <div className={style.content}>
        <div className={style.title}>{course?.title}</div>
        <div className={style.desc}>{course?.desc}</div>
        <div className={style.duration}>
          <span>Duration: </span>
          {course?.duration}
          <div>
            Certification Kit – Exam Voucher, Certification, Book Set, Tools Set
          </div>
        </div>
        <div className={style.ecCouncilContainer}>
          {course?.content?.length > 0 &&
            course?.content?.slice(0, 0)?.map((item, index) => (
              <div key={index} className={style.courseContant}>
                {item}
              </div>
            ))}
          {showMore &&
            course?.content?.length > 0 &&
            course?.content?.slice(0)?.map((item, index) => (
              <div key={index} className={style.courseContant}>
                {item}
              </div>
            ))}
        </div>
        <div
          onClick={() => setShowMore(!showMore)}
          className={style.contentBtn}
        >
          {!showMore ? 'View Modules' : 'Show less'}
        </div>
        {course.isCertificateProvided && (
          <div className={style.certificate}>
            Certificate is provided for this course.
          </div>
        )}
      </div>
    </div>
  );
};

export const Courses = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country_code, setcountry_code] = useState('');
  const [phone, setPhone] = useState('');
  const [help, sethelp] = useState('');

  const checkData = () => {
    if (!name || !validate(email) || !phone || !help) {
      return false;
    }
    return true;
  };

  const notify = () =>
    toast.success('We will contact you soon!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: '#1c1e2e', color: '#fff' },
    });
  return (
    <div className={style.coursesContainer}>
      {/* Section 1 */}
      <ScrollTop />
      <div className={style.section1}>
        <div className={style.title}>
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.leftFloatingEle}
          />
          Learn With Us
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.rightFloatingEle}
          />
        </div>
        <div className={style.couseInfo}>
          Cyber Security has become one of the most important domains today,
          owing to the increasing digitization of data, and thus increasing
          number of cyber-attacks. This calls for high-level awareness among
          people along with the need for an efficient and skilled cybersecurity
          workforce. And the best way to achieve both is through TRAINING.
          <br />
          We provide a range of training programs, workshops, seminars, webinars
          etc. for all, covering a broad range of topics in Cyber Security and
          Digital Forensics. These can be covered in various ways, targeting the
          different needs of people like -
          <ul>
            <li>Awareness </li>
            <li>Career opportunities </li>
            <li>Specialized domain training etc.</li>
          </ul>
        </div>
      </div>

      {/* Section 2 */}
      <div className={style.section2}>
        <div className={style.courses}>
          {COURSES?.map((course, index) => (
            <div
              className={`${style.course} ${
                index % 2 === 0 ? style.reverse : null
              }`}
            >
              <div className={style.serviceImg}>
                <img src={course.img} alt="services" />
              </div>
              <div className={style.content}>
                <div className={style.title}>{course.title}</div>
                <div className={style.desc}>
                  {course.desc.split('\n').map((text) => (
                    <div>{text}</div>
                  ))}
                </div>
                <div className={style.duration}>
                  <span>Duration: </span>
                  {course.duration}
                  <div>Certificate is provided for this course.</div>
                </div>
                {course.isCertificateProvided && (
                  <div className={style.certificate}>
                    Certificate is provided for this course.
                  </div>
                )}
              </div>
            </div>

          ))}
         <div className={style.btnContainer} style={{width:"120px",marginTop:"-25px",backgroundColor:"#ff7c1f",borderRadius:"25px",textAlign:"center"}}>
            <div
             
              className={style.btn}
            >
              <a href="/courses/basic" style={{textDecoration:"none",color:"white"}}>
              Enroll

              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={style.saperator}></div>
      {/* Section 2 */}
      <div className={style.section2}>
        <div className={style.mainTitle}>
          {' '}
          <img src={ECCouncil} alt="EC-council" />
        </div>
        <div className={style.desc}>
          EC-Council is one of the leading global certification bodies in the
          field of cybersecurity, offering a wide range of certifications which
          have been acclaimed worldwide. We offer 4 major certification courses
          by EC-Council which are:
        </div>
        <div className={style.courses}>
          {EC_COUNCIL?.map((course, index) => (
            <CourseContent key={index} course={course} index={index} />
          ))}
        </div>
      </div>
      {/* Section 3 */}
      <div className={style.section3}>
        <img
          className={style.floatingEle1}
          src={circuitImg}
          alt="floatingElement"
        />
         {/* <img
         className='learn-image'
          src={learn}
          alt="floatingElement"
        /> */}
        <div className={style.toRight}>
          <div className={style.title}>Need more details?</div>
          <div className={style.subTitle}>
            Require customised Cyber Security trainings?
            <br />
            We are all ears . . .
          </div>
          <div className={style.formContainer}>
            <div className={style.inputContainer}>
              <label htmlFor="name">Name</label>
              <br />
              <input
                type="text"
                style={{
                  color:"white"
                }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={style.inputContainer}>
              <label htmlFor="name">Email</label>
              <br />
              <input
              style={{
                color:"white"
              }}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            
            <div className={style.inputContainer}>
            <label htmlFor="country code">Phone Number (With Country Code)</label>
              <br/>
              <span style={{display: 'inline-block', width: '13px', height: '13px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" fill='white'/></svg>
              </span>

              <input
                type="number"
                style={{width: "30px", color:"white"}}
                name={phone}
                pattern="[1-9][0-9]{0,2}"
                onChange={(e) => setcountry_code(e.target.value)}
              />
              <span>           </span>
              <input
              style={{
                width: "220px",
                color:"white"
              }}
                type="text"
                name={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className={style.inputContainer}>
              <label htmlFor="name">How can we help?</label>
              <br />
              <input
              style={{
                color:"white"
              }}
                type="text"
                name={help}
                onChange={(e) => sethelp(e.target.value)}
              />
            </div>
          </div>
          <div className={style.btnContainer}>
            <div
              onClick={() => {
              
                  sendEmail(name, email, phone, help,"Courses");
              }}
              className={style.btn}
            >
              Enquire
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
