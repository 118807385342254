module.exports = Object.freeze([
    {
        "queId":0,
        "question":"Do you own IT assets(computers/laptops/mobiles/routers/switches/internet/servers etc.)?",
        "options":[
            {
                "optionName":"yes",
                "weight":2,
                "name":"org_1_1"
            },
            {
                "optionName":"no",
                "weight":1,
                "name":"org_1_2"
            }
        ]
    },
    {
        "queId":1,
        "question":"Do you own IT assets(computers/laptops/mobiles/routers/switches/internet/servers etc.)?",
        "options":[
            {
                "optionName":"yes",
                "weight":2,
                "name":"org_2_1"
            },
            {
                "optionName":"no",
                "weight":1,
                "name":"org_2_2"
            }
        ]
    },
    {
        "queId":2,
        "question":"Are you aware of the nature of your IT infrastructure network (Public/Private/DMZ/Outsourced etc.)?",
        "options":[
            {
                "optionName":"yes",
                "weight":1,
                "name":"org_3_1"
            },
            {
                "optionName":"no",
                "weight":2,
                "name":"org_3_2"
            }
        ]
    },
    {
        "queId":3,
        "question":"Do you manage the confidential information of your clients?",
        "options":[
            {
                "optionName":"yes",
                "weight":2,
                "name":"org_4_1"
            },
            {
                "optionName":"no",
                "weight":1,
                "name":"org_4_2"
            }
        ]
    },
    {
        "queId":4,
        "question":"Do you know that employees can be a major source of cyber threats?",
        "options":[
            {
                "optionName":"yes",
                "weight":1,
                "name":"org_5_1"
            },
            {
                "optionName":"no",
                "weight":2,
                "name":"org_5_2"
            }
        ]
    },
    {
        "queId":5,
        "question":"Out of the following, what according to you is the impact of a cyber-attack?  1. Data Loss   2. Financial Loss   3. Reputational Loss   4. Operational Loss ",
        "options":[
            {
                "optionName":"Only 2,3&4",
                "weight":2,
                "name":"org_6_1"
            },
            {
                "optionName":"Only 1&2",
                "weight":3,
                "name":"org_6_2"
            },
            {
                "optionName":"Only 1",
                "weight":4,
                "name":"org_6_3"
            },
            {
                "optionName":"All of the above 1",
                "weight":1,
                "name":"org_6_4"
            }
        ]
    }
    
    
])