import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from 'email-validator';
import { Link } from 'react-router-dom';
import style from './style.module.scss';
import floatingElement from '../../assets/images/circuit.png';
import { SERVICES } from './services.data';
import { Modal } from '../../Components';

import { scrollToTop } from '../../utils/scrollToTop';
import { ScrollTop } from '../../Components/ScrollTop';
import axios from "axios";
import emailjs from "@emailjs/browser";
export const ServiceModal = ({ showModal, setShowModal, setShowToast }) => {

  const [input, setInput] = useState({
    name: '',
    email: '',
    phone: ''
  })

  const [enquireType,setEnquireType]=('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [Organization,setOrganization]=useState('');
  const checkData = () => {
    if (!name || !validate(email)|| phone.length!==10) {
      return false;
      
    }
    return true;
  };

console.log(name,email,phone)
  const handleClick=async(event)=>{
    event.preventDefault();
    var data={
      message_type:"Service Enquiry",
      from_name:name,
      from_email:email,
      course_name:"Basic Computing",
      from_phone:phone,
      from_org:Organization,
      message:"This message is from service Enquriy Page"
    }
    if(!checkData()){
      if(phone.length!==10){
        alert("please enter a 10 digit Number");
      }
      else if (!name){
        alert("please enter name");
      }
      else if(!validate(email)){
        alert("Please enter a valid email");
      }
    }
    else{

    console.log(process.env.REACT_APP_SERVICE_ID);
    setShowToast();
    setShowModal(false);
   emailjs
      .send(
       "service_upoilro",
        "template_4boev88",
        data,
       "VUn8RzMdyL_5aaWCz"
      )
      .then(
        
        (result) => {
         
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          alert(error)
        }
      );
    // const res = await axios.post('http://localhost:3000/vigil/add_user', {name,email,phone})
    // console.log(res);
      }
  }
  return (
    <div className={style.serviceModalContainer}>
      <div className={style.titleContainer}>
        <div className={style.modalTitle}>{showModal}</div>
        <div className={style.cancleBtn} onClick={() => setShowModal(false)}>
          
        </div>
      </div>
      <div className={style.subContent}>
        <div className={style.desc} style={{color:"#ff7c1f"}}>
        Basic Computing & System Security – Training Course
        </div>
      </div>
      <div className={style.formContainer}>
        <div className={style.inputContainer}>
          <label htmlFor="name">Name*</label>
          <br />
          <input
          name='name'
            type="text"
            id="name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            required
            maxlength="20"
          />
        </div>
        <div className={style.inputContainer}>
          <label htmlFor="email">Email address*</label>
          <br />
          <input
          name='email'
            type="email"
            id="email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            required
          />
        </div>
        <div className={style.inputContainer}>
                  <label htmlFor="phone">Contact No.*</label>
                  <br />
                  <input
                  name='phone'
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={(e)=>setPhone(e.target.value)}
                    required
                    maxlength="10"
                  />
                </div>
                <div className={style.inputContainer}>
                  <label htmlFor="org">Organization*</label>
                  <br />
                  <input
                  name='Organization'
                    type="text"
                    id="org"
                    value={Organization}
                    onChange={(e)=>setOrganization(e.target.value)}
                    required
                  />
                </div>
      </div>
      <br/>
      <p style={{color:"#ff7c1f"}}>Price: <span style={{color:"white"}}>Rs.1500 + GST</span></p>
      <div className={style.btnContainer}>
        <div
          onClick={handleClick}
          className={style.btn}
          style={{
            textAlign:"center"
          }}
        >
          Submit
        </div>
      </div>
      <br></br>
      <br/>
      <a href='/terms' style={{textDecoration:"none"}}> <span style={{color:"white",marginRight:"3px"}}>Terms and Conditions |</span></a> <a href='/refund'><span style={{color:'white',marginRight:"3px",textDecoration:"none"}}>Refund Policy |</span></a> <a href='/privacy-policy' style={{textDecoration:"none"}}> <span style={{color:"white"}}>Privacy Policy</span></a>
    </div>
  );
};

export const Basic = () => {
  const [selectedService, setSelectedService] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const notify = () =>
    toast.success('Registration Succcess!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { background: '#1c1e2e', color: '#fff' },
    });
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className={style.servicesContainer}>
      {/* Section 1 */}
      <ScrollTop />
      <div className={style.section1}>
        <div className={style.title} style={{
          fontSize:"2.5rem"
        }}>
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.leftFloatingEle}
          />
         Register
          <img
            src={floatingElement}
            alt="floatingElement"
            className={style.rightFloatingEle}
          />
        </div>
     
      </div>
   
      {/* Section 2 */}
      {/* Section 3 */}
      <div className={style.modal} style={{display:"flex",justifyContent:"center"}}>
      
          <ServiceModal
            showModal={showModal}
            setShowModal={setShowModal}
            setShowToast={() => notify()}
          />
    
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: '50px' }}
      />
    </div>
  );
};
