import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default function WhyUS(){
    return(
        <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#18122B', color: '#fff',fontFamily:  "sans-serif !important" }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date=""
    iconStyle={{ background: '#FF9551', color: '#fff' }}
  >
    <p >
    We are a one-stop solution for your cybersecurity needs, whether
    it is cyber forensic or compliance audits, we have it all.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date=""
    contentStyle={{ background: '#18122B', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  >
    <p>
    Our low pricing doesn’t compromise the quality of our service.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date=""
    contentStyle={{ background: '#18122B', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    iconStyle={{ background: '#FF9551', color: '#fff' }}
  >
    <p>
    We have a highly qualified and experienced team, who have handled
              individual cases to big organization projects.    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date=""
    contentStyle={{ background: '#18122B', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
  >

    <p>
    We pride ourselves on our transparency with our clients, our
              methods of explaining cybersecurity are made considering everyone
              (laymen to professionals). We also provide detailed information
              report with all of our services.
    </p>
  </VerticalTimelineElement>

</VerticalTimeline>
    )
}
