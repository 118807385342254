import infoProtectionImg from '../../assets/images/info-protection.png';
import managedIt from '../../assets/images/managed-it.png';
import privacyAssurance from '../../assets/images/privacy-assurance.png';

export const cards = [
  {
    img: infoProtectionImg,
    title: 'Information Protection',
    desc: 'To protect confidential information from being stolen and/or modified by cyber criminals.',
  },
  {
    img: managedIt,
    title: 'Managed IT',
    desc: 'To manage the IT assets of individuals and organizations in a secure and efficient manner.',
  },
  {
    img: privacyAssurance,
    title: 'Privacy Assurance',
    desc: 'To assure people – customers as well as employees – of their privacy in the cyber world.',
  },
];

export const SurveyQuestion = [
  {
    question: 'Who are you ?',
    // options: ['Individual', 'Organization'],
    options:[
      {
        "optionName":"Individual",
        "weight":"Individual",
        "name":"indi"
    },
    {
        "optionName":"Organization",
        "weight":"Organization",
        "name":"org"
    }
    ]
  },
 
]