import React from 'react';

import style from './style.module.scss';

export const Card = ({ img, title, desc }) => {
  return (
    <div className={style.card}>
      <div className={style.cardImg}>
        <img src={img} alt="card" />
      </div>
      <div className={style.cardTitle}>{title}</div>
      <div className={style.cardDesc}>{desc}</div>
    </div>
  );
};
