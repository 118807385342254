import Selfless from '../../assets/images/Selfless.png';
import Ethical from '../../assets/images/Ethical.png';
import Compliant from '../../assets/images/Compliant.png';
import Uplifting from '../../assets/images/Uplifting.png';
import Reliable from '../../assets/images/Reliable.png';
import Economic from '../../assets/images/Economic.png';

export const SECURE_INFO = [
  {
    initials: 'S',
    title: 'Selfless',
    img: Selfless,
    desc: 'Your security is our highest priority.',
  },
  {
    initials: 'E',
    title: 'Ethical',
    img: Ethical,
    desc: 'We will NEVER go over “To the Dark Side”',
  },
  {
    initials: 'C',
    title: 'Compliant',
    img: Compliant,
    desc: 'Customers are the Heroes of our Story',
  },
  {
    initials: 'U',
    title: 'Uplifting',
    img: Uplifting,
    desc: 'We inspire to practice Cyber Security as a daily routine',
  },
  {
    initials: 'R',
    title: 'Reliable',
    img: Reliable,
    desc: 'We always choose what is right, instead of what is easy',
  },
  {
    initials: 'E',
    title: 'Economic',
    img: Economic,
    desc: 'Cyber Security is for everyone,  and not a luxury',
  },
];
