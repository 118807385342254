module.exports={
    question :  [
        {
            "queId":0,
            "question":"Do you use computer/mobile/any other digital device? ",
            "options":[
                {
                    "optionName":"yes",
                    "weight":2,
                    "name":"ind_1_1"
                },
                {
                    "optionName":"no",
                    "weight":1,
                    "name":"ind_1_2"
                }
            ]
        },
        {
            "queId":1,
            "question":"Do you use computer/mobile/any other digital device? ",
            "options":[
                {
                    "optionName":"yes",
                    "weight":2,
                    "name":"ind_2_1"
                },
                {
                    "optionName":"no",
                    "weight":1,
                    "name":"ind_2_2"
                }
            ]
        },
        {
            "queId":2,
            "question":"Are you on social media or use internet in any other way? ",
            "options":[
                {
                    "optionName":"yes",
                    "weight":2,
                    "name":"ind_3_1"
                },
                {
                    "optionName":"no",
                    "weight":1,
                    "name":"ind_3_2"
                }
            ]
        },
        {
            "queId":3,
            "question":"Do you use anti-virus or firewall or any other security tool in your computer/mobile?",
            "options":[
                {
                    "optionName":"yes",
                    "weight":1,
                    "name":"ind_4_1"
                },
                {
                    "optionName":"no",
                    "weight":2,
                    "name":"ind_4_2"
                }
            ]
        },
        {
            "queId":4,
            "question":"Do you store personal data/banking data/any other confidential data digitally?",
            "options":[
                {
                    "optionName":"yes",
                    "weight":2,
                    "name":"ind_5_1"
                },
                {
                    "optionName":"no",
                    "weight":1,
                    "name":"ind_5_2"
                }
            ]
        },
        {
            "queId":5,
            "question":"Even if you use tools like anti-virus, firewall etc., you can still be attacked. Do you agree with this statement?",
            "options":[
                {
                    "optionName":"yes",
                    "weight":1,
                    "name":"ind_6_1"
                },
                {
                    "optionName":"no",
                    "weight":2,
                    "name":"ind_6_2"
                }
            ]
        }
        
        
    ]
}

