import React from 'react';

import style from './style.module.scss';

export const BackShadowTitle = ({
  taglineText,
  shadowText,
  taglineTextStyle,
  shadowTextStyle,
}) => {
  return (
    <div className={style.mainTitleText}>
      <div
        className={style.taglineText}
        style={taglineTextStyle && taglineTextStyle}
      >
        {taglineText}
      </div>
      <div
        className={style.backText}
        style={shadowTextStyle && shadowTextStyle}
      >
        {shadowText}
      </div>
    </div>
  );
};
