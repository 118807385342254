import DLP from '../../assets/images/DLP.png';
import ComputerTroubleshooting from '../../assets/images/Computer Troubleshooting.png';
import CSS_Device_Hardening from '../../assets/images/CSS_Device_Hardening.png';
import networkSetup from '../../assets/images/Network Setup.png';
import securityPolicy from '../../assets/images/Security Policy.png';
import DataRecovery from '../../assets/images/Data Recovery.png';
import cybercrimeInvestigation from '../../assets/images/Cybercrime Investigation.png';
import postcybercrimeRestoration from '../../assets/images/Post-cybercrime restoration.png';
import networkSecurityTesting from '../../assets/images/Network Security Testing.png';
import websiteSecurityTesting from '../../assets/images/Website Security Testing.png';
import mobileAppSecurityTesting from '../../assets/images/Mobile App Security Testing.png';
import ISO27001 from '../../assets/images/ISO 27001.png';
import NIST from '../../assets/images/NIST.png';
import HIPAA from '../../assets/images/HIPAA.png';
import WebDevelopmentFullStack from '../../assets/images/Web Development - Full Stack.png';
import WebDevelopmentCMS from '../../assets/images/Web Development - CMS.png';
import ContentWriting from '../../assets/images/Content Writing.png';
import DomainHosting from '../../assets/images/Domain Hosting.png';
import DigitalMarketing from '../../assets/images/Digital Marketing.png';
import COBITRiskManagement from '../../assets/images/COBIT Risk Management.png';
import NISTRiskManagement from '../../assets/images/NIST Risk Management.png';
import MSS_DLP from '../../assets/images/MSS_DLP.png';
import MSS_MT from '../../assets/images/MSS_MT.png';
import NSS_IPS from '../../assets/images/NSS_IPS.png';
import VAPT_SET from '../../assets/images/VAPT_SET.png';
import RM_CRH from '../../assets/images/RM_CRH.png';
import MobileDeviceHardening from '../../assets/images/Mobile Device Hardening.png';

export const SERVICES = [
  {
    serviceName: 'Computer Security Solutions',
    desc: 'Take precautions beforehand instead of action afterward.  Preventing cyber-attacks on your computer systems made easy with our simple, yet effective solutions to enhancing your computer’s security.',
    services: [
      {
        name: 'Device Hardening',
        desc: 'Device hardening is the term used for secure configuration of computers, used to eliminate security issues which ultimately reduces their risk exposure. VCF believes in the saying “Prevention is better than cure”, hence providing you with cost-effective device hardening solutions for personal and office computers. VCF performs a series of tests to determine a computer’s existing security capabilities and enhances its protection to withstand cyber-attacks.',
        img: CSS_Device_Hardening,
      },
      {
        name: 'Data Loss Protection',
        desc: 'Data loss prevention (DLP) is the concept of real-time detection of data breaches and other cyber-attacks, and prevention of the loss of crucial data due to such attacks. VCF provides you customized DLP solutions for computers, fit to your needs. Right from choosing the best solution & implementing it to managing your DLP requirements all year round, VCF ensures smooth DLP management.',
        img: DLP,
      },
      {
        name: 'Computer Troubleshooting',
        desc: 'Computer troubleshooting is the term used for small, daily issues which arise with personal computers and laptops. These may be slow system, issues with network connectivity and many more. VCF experts solve your day-to-day computer issues in minutes, providing remote and home visit services as well.',
        img: ComputerTroubleshooting,
      },
    ],
  },
  {
    serviceName: 'Mobile Security Solutions',
    desc: 'Safeguard your mobile phones, and thus your sensitive data from hackers with our technology plus human-driven security approach.',
    services: [
      {
        name: 'Device Hardening ',
        desc: 'Device hardening is the term used for secure configuration of mobile phones, used to eliminate security issues which ultimately reduces their risk exposure. VCF believes in the saying “Prevention is better than cure”, hence providing you with cost-effective device hardening solutions for your mobile phones. VCF performs a series of tests to determine a mobile phone’s existing security capabilities and enhances its protection to withstand cyber-attacks.',
        img: MobileDeviceHardening,
      },
      {
        name: 'Data Loss Protection',
        desc: 'Data loss prevention (DLP) is the concept of real-time detection of data breaches and other cyber-attacks, and prevention of the loss of crucial data due to such attacks. VCF provides you customized DLP solutions for mobile phones, fit to your needs. Right from choosing the best solution & implementing it to managing your DLP requirements all year round, VCF ensures smooth DLP management.',
        img: MSS_DLP,
      },
      {
        name: 'Mobile Troubleshooting ',
        desc: 'Mobile troubleshooting is the term used for small, daily issues which arise with mobile phones. These may be slow charging, charging failure, issues with network connectivity and many more. VCF experts solve your day-to-day mobile phone issues in minutes, providing remote and home visit services as well',
        img: MSS_MT,
      },
    ],
  },
  {
    serviceName: 'Network Security Solutions',
    desc: 'Secure network design setup, implementation of security tools like firewall, VPN etc., design of strong security policies and procedures, all combined in a customized fashion to provide defence-in-depth protection to your organization.',
    services: [
      {
        name: 'Secure Network Setup',
        desc: 'Secure network setup refers to designing/re-designing of an organization’s network infrastructure, and implementing necessary network security solutions to leave minimal loopholes in the overall IT infrastructure. VCF experts employ a defence-in-depth architecture as a form of layered security approach to design a secure, robust and adaptable network for your organization.',
        img: networkSetup,
      },
      {
        name: 'Anti-Virus | Firewall | VPN | IDS | IPS Solutions',
        desc: 'Security tools have become a necessity in today’s cybercrime-infested world to protect our digital devices and networks. Be it an individual user or a complete organization, security tools like anti-virus, firewall, IDS, IPS, VPN etc. are essential for all. VCF provides complete solutions for these tools as per your specific needs. This includes selecting the ideal tool which is cost-effective and covers all your security requirements, installation of the tool, basic training of tool management and more.',
        img: NSS_IPS,
      },
      {
        name: 'Cybersecurity Policy & Procedure Design',
        desc: 'Cybersecurity policies and procedures define the specific rules for following security practices in an organization and the correct methods to implement them in an effective manner. These form the base of cybersecurity in all organizations. VCF allows you to choose from a wide range of custom-designed cybersecurity policies, catering to your organization’s need and helping you to achieve a beneficial cybersecurity implementation. VCF carries out the implementation of the policy rules and ensures their success by training the organization staff on easy and efficient adherence to the same. ',

        img: securityPolicy,
      },
    ],
  },
  {
    serviceName: 'Digital Forensics Solutions',
    desc: 'Hassle-free deleted data recovery, investigation of hacked devices, forensic clean-up of devices provided with total confidentiality.',
    services: [
      {
        name: 'Deleted Data Recovery',
        desc: 'Deleted data recovery consists of recovering data, deleted and existing, from storage devices like hard disks (laptops & personal computers), pen drives and mobile phone storage. VCF applies a range of digital forensics tools and techniques to recover maximum possible data from storage devices, taking utmost care that storage devices and existing data are not affected in any way.',

        img: DataRecovery,
      },
      {
        name: 'Cybercrime Investigation',
        desc: 'Cybercrime investigation is the process that is aimed at gathering digital information, analysing it for potential evidence and finding the source of cyber-attacks. The target of these cyber-attacks can be individuals as well as organizations. VCF combines the best tools and techniques with forensics expertise to perform investigation of cybercrimes like malware attacks, DoS attacks, digital identity theft etc. VCF also ensures that the affected devices and/or network are free from the malicious content.',

        img: cybercrimeInvestigation,
      },
      {
        name: 'Post-Cybercrime Restoration',
        desc: 'Post-cybercrime restoration consists of a methodical approach to reinstate an organization’s daily operations after the impact of a cybercrime. This includes assessment of the impact, forensic clean-up of the IT infrastructure, secure initiation of core operations and training employees for adaptability to existing work environment. VCF enables a fast and efficient restoration of your organization after a cybercrime and makes sure that necessary operational, financial and legal measures are taken in a timely manner.',

        img: postcybercrimeRestoration,
      },
    ],
  },
  {
    serviceName: 'Vulnerability Assessment & Penetration Testing (VAPT)',
    desc: 'We uncover all loopholes existing in your network, website, mobile and other applications, and assess the extent of threat they pose to your organization by using a combination of automated and manual testing procedures aimed at minimizing the overall risk from cyber-attacks. Here, Vulnerability Assessment (VA) is the process of finding all possible vulnerabilities/loopholes/issues in your IT infrastructure and Penetration Testing (PT) is the process of assessing the impact of cyber-attacks by carrying out a number of ethical hacking tests. This enables organizations to recognize the security risk that exists in their IT infrastructure and remove or mitigate it for a secure environment.',
    services: [
      {
        name: 'Network Testing',
        desc: 'VCF’s network testing process consists of conducting VAPT on organizations’ network through external and internal testing approaches carried out to assess the risk from external/unknown attackers and internal/insider/known attackers.',

        img: networkSecurityTesting,
      },
      {
        name: 'Web Application Testing',
        desc: 'VCF’s website testing process consists of conducting Web Application Penetration Testing (WAPT) based on the global testing frameworks of OWASP Top 10 and SANS 25. The testing process includes various types of testing phases – Threat Modeling, Static Application Security Testing (SAST) and Dynamic Application Security Testing (DAST) carried out during different phases of the SDLC operations. ',

        img: websiteSecurityTesting,
      },
      {
        name: 'Mobile Application Testing',
        desc: 'VCF’s mobile application testing process consists of conducting Mobile Application Penetration Testing (MAPT) based on the global testing framework of OWASP Mobile Top 10. The testing includes both static and dynamic analysis of mobile application security to uncover both application bugs and vulnerabilities in a running mobile app. ',

        img: mobileAppSecurityTesting,
      },
      {
        name: 'Social Engineering Testing',
        desc: 'VCF’s social engineering testing process consists of carrying various social engineering attack simulations on organization employees in order to assess their awareness and level of susceptibility against attacks like phishing, vishing, baiting etc.',
        img: VAPT_SET,
      },
    ],
  },
  {
    serviceName: 'Information Security Auditing & Consultation',
    desc: 'Make your organization a security stronghold by testing its compliance with various global standards of information security. We ensure total compliance with the required security standards and guide through the complete process.',
    services: [
      {
        name: 'ISO 27001',
        desc: 'ISO 27001 is the standard specified by International Organization for Standardization (ISO) for Information Security Management System (ISMS) and ensures that all organizational processes, operations and decisions follow proper information security practices. ISO 27001 is one of the most sought after and necessary standard for organizations today because of the use of technology everywhere. VCF provides in-depth auditing services in compliance with the 14 controls of ISO 27001 and also offers consulting services for adhering to this standard.',

        img: ISO27001,
      },
      {
        name: 'NIST Cybersecurity Framework',
        desc: 'NIST Cybersecurity Framework is a cybersecurity controls framework designed by The National Institute of Standards and Technology (NIST), U.S. Similar to ISO 27001 standard, it ensures that necessary cybersecurity practices are followed in organizations. For less mature organizations, this framework benefits by proving a simple, yet effective cybersecurity management structure. VCF provides in-depth auditing services in compliance with the 5 functions of NIST Cybersecurity Framework and also offers consulting services for adhering to this framework.',

        img: NIST,
      },
      {
        name: 'HIPAA',
        desc: 'The Health Insurance Portability and Accountability Act (HIPAA) requires all organizations in the healthcare sector, handling patients’ confidential information to comply with it and thus ensure that sensitive patient information stored digitally is safe from cyber-attacks. VCF provides in-depth auditing services in compliance with the 5 sections/titles of HIPAA and also offers consulting services for adhering to this Act.',

        img: HIPAA,
      },
    ],
  },
  {
    serviceName: 'Web Design & Development',
    desc: 'Bring your products and services to customers through the easiest and most popular channel – let us build a website for you!',
    services: [
      {
        name: 'Web Development – Full Stack',
        desc: 'Full stack web development encompasses complete website design from backend to frontend including database design, debugging and testing. Components include multiple languages to choose from –  ⦁	Frontend – HTML, HMTL5, CSS3, Twitter Bootstrap, JavaScript, jQuery  ⦁	Backend – Node.js, Meteor.js, Angular 2, PHP, Ruby on Rails  ⦁	Database – MySQL, PostgreSQL, MongoDB, CouchDB, Apache Cassandra ⦁	Other Essential Technologies – Memcached, Redis, Apache Lucene, Apache Solr. VCF ensures that you get a website designed precisely to your requirements using the best suitable combination of full stack technologies.',

        img: WebDevelopmentFullStack,
      },
      {
        name: 'Web Development – CMS',
        desc: 'CMS-based web development includes platform-based development which allows to create and manage websites easily with lesser programming languages than full stack development. Commonly used CMS platforms include –  ⦁	WordPress ⦁	Joomla ⦁	Drupal etc. VCF brings to you a wide range of CMS platforms to choose from for your perfect website at the best price.',

        img: WebDevelopmentCMS,
      },
      {
        name: 'Content Writing & Creation',
        desc: 'Content writing and creation includes designing of all the required text and multimedia content for client websites. VCF experts ensure that your views reach the customers through appealing media content on your website and comprehensive descriptive content.',

        img: ContentWriting,
      },
      {
        name: 'Domain Hosting Consultation',
        desc: 'Domain hosting consultation includes providing expert guidance in choosing the best domain hosting solution for websites. VCF experts help you in finding the most suitable domain hosting solution for your website at best prices after careful analysis of your requirements. ',

        img: DomainHosting,
      },
      {
        name: 'Digital Marketing',
        desc: 'Digital marketing services consist of promotion of websites through various SEO (Search Engine Optimization) techniques which provide better website visibility on the internet. VCF uses numerous SEO tools, tips & tricks to ensure maximum reach and promotion of your website.',

        img: DigitalMarketing,
      },
    ],
  },
  {
    serviceName: 'Risk Management',
    desc: 'Infuse security into your organization with the help of our experts who proactively manage risks all year round based on standards frameworks like NIST and COBIT, and make sure the external, as well as internal threats, stay to a bare minimum.',
    services: [
      {
        name: 'COBIT',
        desc: 'COBIT (Control Objectives for Information and Related Technology) framework is a popular risk management framework for organizations. This framework is designed to ensure effective governance of IT infrastructure and is a standard requirement for organizations in all sectors. VCF provides implementation and management services in compliance with the 5 principles of COBIT 5 and also offers consulting services for adhering to this framework.',

        img: COBITRiskManagement,
      },
      {
        name: 'NIST Risk Management Framework',
        desc: 'NIST Risk Management Framework is a risk management framework designed to infuse security management into the system development lifecycle of an organization. This framework consists of a stage-driven approach which enables year-round risk management. VCF provides implementation and management services in compliance with the 7 stages of NIST Risk Management Framework and also offers consulting services for adhering to this framework.',

        img: NISTRiskManagement,
      },
    ],
  },
  {
    serviceName: 'Cybercrime Reporting Help',
    desc: 'Take timely action in case of cyber-attacks by letting us guide you through the legal as well as technical steps needed in response. We make sure that cybercrimes are reported to the proper authorities.',
    services: [
      {
        name: 'Cybercrime Reporting Help',
        desc: 'Take timely action in case of cyber-attacks by letting us guide you through the legal as well as technical steps needed in response. We make sure that cybercrimes are reported to the proper authorities.',
        img: RM_CRH,
      },
    ],
  },
];
